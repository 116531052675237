<template>
	<div class="privacy-terms">
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="container">
					<div class="container_inner default_template_holder clearfix page_container_inner">
						<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-2">
										<div class="vc_column-inner">
											<div class="wpb_wrapper"></div>
										</div>
									</div>
									<div class="wpb_column vc_column_container vc_col-sm-8">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2 style="text-align: center;">Privacy Policy</h2>
														<p style="text-align: center;">Your privacy is important to PBE.
															This privacy statement provides information about the
															information that PBE collects, and the ways in which PBE
															uses that information.</p>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h3><b>What information do we collect?</b></h3>
														<p><span style="font-weight: 400;">If you fill out surveys, ergo
																audits, request services, or subscribe to our blog, we
																may collect contact information pertaining to your
																workplace, including: your name and work email address.
																In addition, when you take our self-assessment or are
																the subject of an ergonomic evaluation we may also
																collect information associated with your work location,
																department, and general well-being. If you are the
																subject of a virtual (remote) evaluation, or any other
																form of virtual assessment, PBE neither records video or
																audio nor captures photos. PBE’s evaluators can neither
																record nor capture your screen content.<br />
															</span></p>
														<p>&nbsp;</p>
														<h3><b>How we use the information we collect</b></h3>
														<p><span style="font-weight: 400;">Your contact information is
																used to provide you with a summary of any
																self-assessments, ergonomic evaluations, surveys,
																audits, and service requests in which you participate,
																as well as to reset your password, if and when
																necessary. In addition, your information may also be
																used to follow up with you and/or your organization
																about your needs.</span></p>
														<p>&nbsp;</p>
														<h3><b>How do we share or transfer data?</b></h3>
														<p><span style="font-weight: 400;">Your identifying information,
																as defined above, may be shared with designated members
																of your organization for internal use only. We will
																never sell or share your information with any outside
																organization.</span></p>
														<p>&nbsp;</p>
														<h3><b>Your options for data collection and use</b></h3>
														<p><span style="font-weight: 400;">The required questions during
																the registration process, self-assessment, or ergonomic
																evaluation are used to provide services to both you and
																your organization. All other information will be
																considered discretionary and entirely optional.</span>
														</p>
														<p>&nbsp;</p>
														<h3><b>Data Security: Storage &amp; Transmission</b></h3>
														<p><span style="font-weight: 400;">All data is stored and
																transmitted in compliance with HIPAA regulations,
																including standard levels of encryption. In the event
																that any information under our control is compromised as
																a result of a breach of security, we will take
																reasonable steps to investigate the situation and will
																notify you of the security incident in accordance with
																applicable laws and regulations.</span></p>
														<p>&nbsp;</p>
														<h3><b>User DATA PROTECTION</b></h3>
														<p><span style="font-weight: 400;">Subjects specifically located
																in the European Union (GDPR) and California (CCPA); and
																all other geographic and economic regions are advised of
																the following rights.</span><span
																style="font-weight: 400;"> </span></p>
														<ul>
															<li style="font-weight: 400;" aria-level="1"><span
																	style="font-weight: 400;">You may request access to
																	and rectification or erasure of personal data
																	concerning you.</span></li>
															<li style="font-weight: 400;" aria-level="1"><span
																	style="font-weight: 400;">You may request a
																	restriction of data processing concerning
																	you.</span></li>
															<li style="font-weight: 400;" aria-level="1"><span
																	style="font-weight: 400;">You may request the
																	personal data which you have provided us concerning
																	you, and you may transmit those data to another
																	controller without hindrance from us.</span></li>
															<li style="font-weight: 400;" aria-level="1"><span
																	style="font-weight: 400;">You may object at any time
																	to our processing of personal data concerning you
																	and you may withdraw your consent for future
																	processing.</span></li>
															<li style="font-weight: 400;" aria-level="1"><span
																	style="font-weight: 400;">If you are a subject in
																	the European Union, and you believe that our
																	processing of personal data relating to you
																	infringes the EU’s General Data Protection
																	Regulation, you may lodge a complaint with your
																	Member State’s designated supervisory
																	authority.</span></li>
														</ul>
														<p><span style="font-weight: 400;">Should you want to exercise
																your right to alter or remove your identifying
																information, please contact us at <a
																	href="mailto:support@pbergo.com">support@pbergo.com</a></span>
														</p>
														<p>&nbsp;</p>
														<h3><b>Links to other sites</b></h3>
														<p><span style="font-weight: 400;">In order to provide you with
																our services, Pbergo.com </span><span
																style="font-weight: 400;">may provide access to
																resources and links to other websites. When you access a
																non-Pbergo.com website, you leave the Pbergo.com website
																and understand that it is independent from Pbergo.com,
																and that Pbergo.com has not reviewed nor is it
																responsible for the content of any linked websites.
																Pbergo.com makes no representations whatsoever about
																such resources or other websites. The inclusion of any
																link to a website does not imply endorsement by
																Pbergo.com of the website or their entities, products,
																or services. All warranties, conditions or other terms
																express or implied as to any such linked website,
																including without limitation as to accuracy, ownership,
																validity, or legality of any content of a linked
																website, are hereby excluded</span><span
																style="font-weight: 400;">. For a list of third-party
																sites in order to access their privacy policies, please
																contact us at: </span><a
																href="mailto:support@pbergo.com"><span
																	style="font-weight: 400;">support@pbergo.com</span></a>
														</p>
														<p>&nbsp;</p>
														<h3><b>Changes and updates to this Privacy Policy</b></h3>
														<p><span style="font-weight: 400;">From time to time, we may
																make changes to our privacy policy. In this event and to
																the best of our ability, we will notify you of any
																changes which may impact the collection, storage, and
																transmission of your data and your rights as it pertains
																to your data.</span></p>
														<p>&nbsp;</p>
														<h3><b>Contacting Us</b></h3>
														<p><span style="font-weight: 400;">If you have any questions or
																comments please contact us at </span><a
																href="mailto:support@pbergo.com"><span
																	style="font-weight: 400;">support@pbergo.com</span></a>
														</p>
														<p>&nbsp;</p>
														<p><b>Terms of Use</b><span style="font-weight: 400;"> can be
																viewed by <router-link :to="{ name: 'terms-of-serivce' }">
																clicking here </router-link>
																</span>
																</p>

													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="wpb_column vc_column_container vc_col-sm-2">
										<div class="vc_column-inner">
											<div class="wpb_wrapper"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vc_row wpb_row section vc_row-fluid " style=' text-align:left;'>
							<div class=" full_section_inner clearfix">
								<div class="wpb_column vc_column_container vc_col-sm-12">
									<div class="vc_column-inner">
										<div class="wpb_wrapper">
											<div class="vc_empty_space" style="height: 52px"><span
													class="vc_empty_space_inner">
													<span class="empty_space_image"></span>
												</span></div>

										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
				

			</div>
		</div>

	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'privacy-policy',
	}
</script>