var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"privacy-terms"},[_c('div',{staticClass:"content content_top_margin"},[_c('div',{staticClass:"content_inner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"container_inner default_template_holder clearfix page_container_inner"},[_c('div',{staticClass:"vc_row wpb_row section vc_row-fluid grid_section",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"section_inner clearfix"},[_c('div',{staticClass:"section_inner_margin clearfix"},[_vm._m(0),_c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-8"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"wpb_text_column wpb_content_element"},[_c('div',{staticClass:"wpb_wrapper"},[_vm._m(4),_vm._m(5),_c('p',[_vm._v(" ")]),_vm._m(6),_vm._m(7),_c('p',[_vm._v(" ")]),_vm._m(8),_vm._m(9),_c('p',[_vm._v(" ")]),_vm._m(10),_vm._m(11),_c('p',[_vm._v(" ")]),_vm._m(12),_vm._m(13),_c('p',[_vm._v(" ")]),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_c('p',[_vm._v(" ")]),_vm._m(18),_vm._m(19),_c('p',[_vm._v(" ")]),_vm._m(20),_vm._m(21),_c('p',[_vm._v(" ")]),_vm._m(22),_vm._m(23),_c('p',[_vm._v(" ")]),_c('p',[_c('b',[_vm._v("Terms of Use")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" can be viewed by "),_c('router-link',{attrs:{"to":{ name: 'terms-of-serivce' }}},[_vm._v(" clicking here ")])],1)])])])])])]),_vm._m(24)])])]),_vm._m(25)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-2"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vc_empty_space",staticStyle:{"height":"52px"}},[_c('span',{staticClass:"vc_empty_space_inner"},[_c('span',{staticClass:"empty_space_image"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpb_text_column wpb_content_element"},[_c('div',{staticClass:"wpb_wrapper"},[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v("Privacy Policy")]),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Your privacy is important to PBE. This privacy statement provides information about the information that PBE collects, and the ways in which PBE uses that information.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vc_empty_space",staticStyle:{"height":"52px"}},[_c('span',{staticClass:"vc_empty_space_inner"},[_c('span',{staticClass:"empty_space_image"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("What information do we collect?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("If you fill out surveys, ergo audits, request services, or subscribe to our blog, we may collect contact information pertaining to your workplace, including: your name and work email address. In addition, when you take our self-assessment or are the subject of an ergonomic evaluation we may also collect information associated with your work location, department, and general well-being. If you are the subject of a virtual (remote) evaluation, or any other form of virtual assessment, PBE neither records video or audio nor captures photos. PBE’s evaluators can neither record nor capture your screen content."),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("How we use the information we collect")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Your contact information is used to provide you with a summary of any self-assessments, ergonomic evaluations, surveys, audits, and service requests in which you participate, as well as to reset your password, if and when necessary. In addition, your information may also be used to follow up with you and/or your organization about your needs.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("How do we share or transfer data?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Your identifying information, as defined above, may be shared with designated members of your organization for internal use only. We will never sell or share your information with any outside organization.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("Your options for data collection and use")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("The required questions during the registration process, self-assessment, or ergonomic evaluation are used to provide services to both you and your organization. All other information will be considered discretionary and entirely optional.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("Data Security: Storage & Transmission")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("All data is stored and transmitted in compliance with HIPAA regulations, including standard levels of encryption. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and will notify you of the security incident in accordance with applicable laws and regulations.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("User DATA PROTECTION")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Subjects specifically located in the European Union (GDPR) and California (CCPA); and all other geographic and economic regions are advised of the following rights.")]),_c('span',{staticStyle:{"font-weight":"400"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("You may request access to and rectification or erasure of personal data concerning you.")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("You may request a restriction of data processing concerning you.")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("You may request the personal data which you have provided us concerning you, and you may transmit those data to another controller without hindrance from us.")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("You may object at any time to our processing of personal data concerning you and you may withdraw your consent for future processing.")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("If you are a subject in the European Union, and you believe that our processing of personal data relating to you infringes the EU’s General Data Protection Regulation, you may lodge a complaint with your Member State’s designated supervisory authority.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Should you want to exercise your right to alter or remove your identifying information, please contact us at "),_c('a',{attrs:{"href":"mailto:support@pbergo.com"}},[_vm._v("support@pbergo.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("Links to other sites")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("In order to provide you with our services, Pbergo.com ")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("may provide access to resources and links to other websites. When you access a non-Pbergo.com website, you leave the Pbergo.com website and understand that it is independent from Pbergo.com, and that Pbergo.com has not reviewed nor is it responsible for the content of any linked websites. Pbergo.com makes no representations whatsoever about such resources or other websites. The inclusion of any link to a website does not imply endorsement by Pbergo.com of the website or their entities, products, or services. All warranties, conditions or other terms express or implied as to any such linked website, including without limitation as to accuracy, ownership, validity, or legality of any content of a linked website, are hereby excluded")]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(". For a list of third-party sites in order to access their privacy policies, please contact us at: ")]),_c('a',{attrs:{"href":"mailto:support@pbergo.com"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("support@pbergo.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("Changes and updates to this Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("From time to time, we may make changes to our privacy policy. In this event and to the best of our ability, we will notify you of any changes which may impact the collection, storage, and transmission of your data and your rights as it pertains to your data.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("Contacting Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("If you have any questions or comments please contact us at ")]),_c('a',{attrs:{"href":"mailto:support@pbergo.com"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("support@pbergo.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-2"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vc_row wpb_row section vc_row-fluid",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"full_section_inner clearfix"},[_c('div',{staticClass:"wpb_column vc_column_container vc_col-sm-12"},[_c('div',{staticClass:"vc_column-inner"},[_c('div',{staticClass:"wpb_wrapper"},[_c('div',{staticClass:"vc_empty_space",staticStyle:{"height":"52px"}},[_c('span',{staticClass:"vc_empty_space_inner"},[_c('span',{staticClass:"empty_space_image"})])])])])])])])
}]

export { render, staticRenderFns }